import React from 'react';
import PropTypes from 'prop-types';

import OrderLocksTable from './OrderLocksTable';


class OrderLocksAccordion extends React.Component {
    static propTypes = {
        allowMultipleOpen: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        const openSections = {};

        this.props.items.forEach(item => {
            if (item.isOpen) {
                openSections[item.idMenuGroup] = true;
            }
        });

        this.state = {
            openSections: openSections
        };
    }

    onClick = key => {
        const {
            props: { allowMultipleOpen },
            state: { openSections },
        } = this;

        const isOpen = !!openSections[key];

        if (allowMultipleOpen) {
            this.setState({
                openSections: {
                    ...openSections,
                    [key]: !isOpen
                }
            });
        } else {
            this.setState({
                openSections: {
                    [key]: !isOpen
                }
            });
        }

        window.getSelection().removeAllRanges();
    };

    render() {
        const {
            onClick,
            props: { items },
            state: { openSections },
        } = this;
        const context = this;
        return (
            <div className="accordion-container">
                <div>
                    <span className="name">{this.props.translations.name}</span>
                    <span className="lockTime">{this.props.translations.lock_time}</span>
                </div>
                <div>
                    { items.length > 0 ? items.map(item => (
                    <div key={item.idMenuGroup}>
                        <div className='checkBox'>
                            <input type="checkbox" onChange={(e) => item.isSelected = !item.isSelected} onClick={this.handleClick} disabled={context.props.locked} />
                        </div>
                        <div className='accordion'>
                            <OrderLocksAccordionSection
                                isOpen={!!openSections[item.idMenuGroup]}
                                locked={context.props.locked}
                                item={item}
                                onClick={onClick}
                                translations={this.props.translations}
                            />
                        </div>
                    </div>
                    )) : <div className="noContent">{this.props.translations.no_content}</div>}
                </div>
            </div>
        );
    }
}

class OrderLocksAccordionSection extends React.Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        locked: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item
        }
    }

    onClick = () => {
        this.props.onClick(this.props.item.idMenuGroup);
    };

    handleNameChanged = (e) => {
        if (e.target.value != '') {
            this.state.item.name = e.target.value;
            this.setState({
                item: this.state.item
            });

        }
    }

    areAnyOrderTimeDifferences() {
        var difference = this.state.item.data.filter(function (item) {
            return item.days != "0" || item.time != "23:59";
        });
        return (difference.length == 0);
    }

    areOrderTimesSame() {
        const allEqual = this.state.item.data.every(item => item.days == this.state.item.data[0].days && item.time == this.state.item.data[0].time);
        return allEqual;
    }

    getLastOrderType() {
        if (this.areAnyOrderTimeDifferences()) {
            return this.props.translations.no_lock_type_chosen;
        }
        else if (this.areOrderTimesSame()) {
            return this.state.item.data[0].days + " " + this.props.translations.days.toLowerCase() + " " + this.props.translations.before + " " + this.state.item.data[0].time;
        }
        return this.props.translations.multiple_lock_times_used;
    }

    onChange = () => {
        this.setState({
            item: this.state.item
        });
    };


    render() {
        const {
            onClick,
            props: { isOpen },
            state: { item }
        } = this;

        return (
            <div className={`section ${(isOpen ? "accordion-open" : "accordion-closed")}`}>
                <div className="labelBar" onClick={onClick}>
                    <span className={!this.props.locked ? "vertical-align":""}>
                        {this.props.locked &&

                            <b>{item.name}</b>
                        }
                        {!this.props.locked &&
                            <input
                                type="text"
                                value={this.state.item.name}
                                onChange={this.handleNameChanged} required
                                onClick={(e) => e.stopPropagation()}
                            />
                        }


                    </span>
                    <span className={`${!this.props.locked ? "vertical-align" : ""} ${(this.areAnyOrderTimeDifferences() ? "noLockType" : "")}`}>{this.getLastOrderType()}</span>
                    <div style={{ float: 'right' }}>
                        {!isOpen && <i className="fa fa-angle-down"></i>}
                        {isOpen && <i className="fa fa-angle-up"></i>}
                    </div>
                </div>
                {isOpen && (
                    <div className="content">
                        <OrderLocksTable locked={this.props.locked} data={item.data} onDataChange={this.onChange} translations={this.props.translations}>
                        </OrderLocksTable>
                    </div>
                )}
            </div>
        );
    }
}


export default OrderLocksAccordion;