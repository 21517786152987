import { CustomerInvoiceAccount } from "./CustomerInvoice";

const input = $("[id$=hf_customerinvoice]")[0] as HTMLInputElement;

export const setValueToInput = (customerInvoices: CustomerInvoiceAccount[]) => {
  const newCustomerInvoices = customerInvoices.map((item) => {
    return {
      Account: item.account,
      AccountType: item.accountType,
      CustomerId: item.customerId,
      MaxAmount: item.maxAmount,
      MinAmount: item.minAmount,
      Part1: item.part1,
      Part2: item.part2,
      Part3: item.part3,
      Part4: item.part4,
      Part5: item.part5,
      Part6: item.part6,
      Part7: item.part7,
      Part8: item.part8,
      Part9: item.part9,
      Part10: item.part10,
      TransactionType: item.transactionType,
    };
  });
  input.value = JSON.stringify(newCustomerInvoices);
};
