import { CustomerInvoiceFormProps, MODE } from "../CustomerInvoice";
import { CustomerInvoiceAccount } from "../Domain/CustomerInvoice";
import { translate } from "../Translations/labelStrings";
import CustomerInvoiceDialog from "./CustomerInvoiceDialog";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

export interface CustomerInvoiceAccountProps {
  customerInvoiceAccount: CustomerInvoiceAccount;
  isDialogOpen: boolean;
  onSubmit: (
    customerInvoiceAccount: CustomerInvoiceFormProps,
    mode: number
  ) => void;
  onAbort: () => void;
  headers: string[];
  mode: number;
}
const CustomerInvoiceForm = ({
  customerInvoiceAccount,
  isDialogOpen,
  onSubmit,
  onAbort,
  headers,
  mode,
}: CustomerInvoiceAccountProps) => {
  const [accountType, setAccountType] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [displayTransactionType, setDisplayTransactionType] = useState("");
  const [displayAccountType, setDisplayAccountType] = useState("");
  const [account, setAccount] = useState("");
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [part1, setPart1] = useState("");
  const [part2, setPart2] = useState("");
  const [part3, setPart3] = useState("");
  const [part4, setPart4] = useState("");
  const [part5, setPart5] = useState("");
  const [part6, setPart6] = useState("");
  const [part7, setPart7] = useState("");
  const [part8, setPart8] = useState("");
  const [part9, setPart9] = useState("");
  const [part10, setPart10] = useState("");

  const handleAccountType = (event: any) => {
    const inputValue = event.target.value;
    const accountTypeData = inputValue === "Cost" ? "Debit" : "Credit";
    setAccountType(accountTypeData);
    setDisplayAccountType(inputValue);
  };
  const handleAccountingFor = (event: any) => {
    const transactionTypeData = event.target.value === "Meal" ? "Meal" : "Item";
    setTransactionType(transactionTypeData);
    setDisplayTransactionType(event.target.value);
  };
  useEffect(() => {
    setAccountType(customerInvoiceAccount.accountType);
    setTransactionType(customerInvoiceAccount.transactionType);
    setDisplayTransactionType(customerInvoiceAccount.displayTransactionType);
    setDisplayAccountType(customerInvoiceAccount.displayAccountType);
    setAccount(customerInvoiceAccount.account);
    setMinAmount(customerInvoiceAccount.minAmount);
    setMaxAmount(customerInvoiceAccount.maxAmount);
    setPart1(customerInvoiceAccount.part1);
    setPart2(customerInvoiceAccount.part2);
    setPart3(customerInvoiceAccount.part3);
    setPart4(customerInvoiceAccount.part4);
    setPart5(customerInvoiceAccount.part5);
    setPart6(customerInvoiceAccount.part6);
    setPart7(customerInvoiceAccount.part7);
    setPart8(customerInvoiceAccount.part8);
    setPart9(customerInvoiceAccount.part9);
    setPart10(customerInvoiceAccount.part10);
  }, [customerInvoiceAccount]);
  const onConfirm = () => {
    onSubmit(
      {
        transactionType,
        accountType,
        account,
        minAmount,
        maxAmount,
        part1,
        part2,
        part3,
        part4,
        part5,
        part6,
        part7,
        part8,
        part9,
        part10,
        displayTransactionType,
        displayAccountType,
      },
      mode
    );
  };

  return (
    <Box>
      <CustomerInvoiceDialog
        open={isDialogOpen}
        onAbort={onAbort}
        title={translate(mode === MODE.ADD ? "Add" : "Edit")}
        onConfirm={onConfirm}
      >
        <table style={{ borderSpacing: "5px" }}>
          <tbody>
            <tr key="AccountingFor">
              <td>
                <label>
                  <strong>{translate("AccountingFor")}</strong>
                </label>
              </td>
              <td>
                <select
                  value={displayTransactionType}
                  onChange={handleAccountingFor}
                  disabled={mode === MODE.EDIT}
                  style={{ width: "100%" }}
                >
                  <option key={"Meal"}>Meal</option>
                  <option key={"Product"}>Product</option>
                </select>
              </td>
            </tr>

            <tr key="AccountType">
              <td>
                <label>
                  <strong>{translate("AccountType")}</strong>
                </label>
              </td>
              <td>
                <select
                  value={displayAccountType}
                  onChange={handleAccountType}
                  style={{ width: "100%" }}
                  disabled={mode === MODE.EDIT}
                >
                  <option key={"Cost"}>Cost</option>
                  <option key={"Revenue"}>Revenue</option>
                </select>
              </td>
            </tr>

            <tr key="MinAmount">
              <td>
                <label>
                  <strong>{translate("MinimumAmount")}</strong>
                </label>
              </td>
              <td>
                <input
                  min={0}
                  type="number"
                  maxLength={15}
                  defaultValue={minAmount}
                  onChange={(e) => setMinAmount(e.target.value)}
                />
              </td>
            </tr>
            <tr key="MaximumAmount">
              <td>
                <label>
                  <strong>{translate("MaximumAmount")}</strong>
                </label>
              </td>
              <td>
                <input
                  min={0}
                  type="number"
                  maxLength={15}
                  defaultValue={maxAmount}
                  onChange={(e) => setMaxAmount(e.target.value)}
                />
              </td>
            </tr>
            <tr key="Account">
              <td>
                <label>
                  <strong>{translate("Account")}</strong>
                </label>
              </td>
              <td>
                <input
                  type="text"
                  maxLength={128}
                  defaultValue={account}
                  onChange={(e) => setAccount(e.target.value)}
                />
              </td>
            </tr>

            <tr key={headers[0]}>
              <td>
                <label>
                  <strong>{headers[0]}</strong>
                </label>
              </td>
              <td>
                <input
                  type="text"
                  maxLength={32}
                  defaultValue={part1}
                  onChange={(e) => setPart1(e.target.value)}
                />
              </td>
            </tr>
            <tr key={headers[1]}>
              <td>
                <label>
                  <strong>{headers[1]}</strong>
                </label>
              </td>
              <td>
                <input
                  type="text"
                  maxLength={32}
                  defaultValue={part2}
                  onChange={(e) => setPart2(e.target.value)}
                />
              </td>
            </tr>

            <tr key={headers[2]}>
              <td>
                <label>
                  <strong>{headers[2]}</strong>
                </label>
              </td>
              <td>
                <input
                  type="text"
                  maxLength={32}
                  defaultValue={part3}
                  onChange={(e) => setPart3(e.target.value)}
                />
              </td>
            </tr>
            <tr key={headers[3]}>
              <td>
                <label>
                  <strong>{headers[3]}</strong>
                </label>
              </td>
              <td>
                <input
                  type="text"
                  maxLength={32}
                  defaultValue={part4}
                  onChange={(e) => setPart4(e.target.value)}
                />
              </td>
            </tr>

            <tr key={headers[4]}>
              <td>
                <label>
                  <strong>{headers[4]}</strong>
                </label>
              </td>
              <td>
                <input
                  type="text"
                  maxLength={32}
                  defaultValue={part5}
                  onChange={(e) => setPart5(e.target.value)}
                />
              </td>
            </tr>
            <tr key={headers[5]}>
              <td>
                <label>
                  <strong>{headers[5]}</strong>
                </label>
              </td>
              <td>
                <input
                  type="text"
                  maxLength={32}
                  defaultValue={part6}
                  onChange={(e) => setPart6(e.target.value)}
                />
              </td>
            </tr>

            <tr key={headers[6]}>
              <td>
                <label>
                  <strong>{headers[6]}</strong>
                </label>
              </td>
              <td>
                <input
                  type="text"
                  maxLength={32}
                  defaultValue={part7}
                  onChange={(e) => setPart7(e.target.value)}
                />
              </td>
            </tr>
            <tr key={headers[7]}>
              <td>
                <label>
                  <strong>{headers[7]}</strong>
                </label>
              </td>
              <td>
                <input
                  type="text"
                  maxLength={32}
                  defaultValue={part8}
                  onChange={(e) => setPart8(e.target.value)}
                />
              </td>
            </tr>

            <tr key={headers[8]}>
              <td>
                <label>
                  <strong>{headers[8]}</strong>
                </label>
              </td>
              <td>
                <input
                  type="text"
                  maxLength={32}
                  defaultValue={part9}
                  onChange={(e) => setPart9(e.target.value)}
                />
              </td>
            </tr>
            <tr key={headers[9]}>
              <td>
                <label>
                  <strong>{headers[9]}</strong>
                </label>
              </td>
              <td>
                <input
                  type="text"
                  maxLength={32}
                  defaultValue={part10}
                  onChange={(e) => setPart10(e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </CustomerInvoiceDialog>
    </Box>
  );
};
export default CustomerInvoiceForm;
