﻿var rootURL = window.location.href.replace(window.location.pathname, '');

function getWithRootUrl(suffix) {
    return rootURL + '/' + suffix;
}

function getBoxRowIndex(obj) {
    var list = $(obj).closest('tr').find('.moveByKey');
    var correctIndex = -1;

    $.each(list, function (index, now) {
        jNow = $(now);
        if (jNow.attr('id') == obj.attr('id')) {
            correctIndex = index;
        }
    });

    return correctIndex;
}

function bindKeyMove() {
    $('.moveByKey').unbind('keydown');

    $('.moveByKey').keydown(function (event) {
        if (event.keyCode == '40' || event.keyCode == '38') {
            var jNow = $(this);
            var boxIndex = getBoxRowIndex(jNow);
            var rowToFindIn;

            if (boxIndex != -1) {
                if (event.keyCode == '38') {
                    rowToFindIn = jNow.closest('tr').prev();
                } else {
                    rowToFindIn = jNow.closest('tr').next();
                }

                if (rowToFindIn.length > 0) {
                    event.preventDefault();
                    var next = $(rowToFindIn.find('.moveByKey')[boxIndex]);
                    next.focus();
                    next.select();
                }
            }
        }
    }); 
}

function setDraggableDiv(elementId) {
    var offetTop = 0, offsetLeft = 0, preClientX = 0, preClientY = 0;
    var printDivElement = document.getElementById(elementId);
    var printDivHeader = document.getElementById(elementId + "Header");
    var oldOnMouseUpHandler = null;
    var oldOnMouseMoveHandler = null;

    printDivHeader.onmousedown = dragMouseDownHandler;

    function dragMouseDownHandler(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        preClientX = e.clientX;
        preClientY = e.clientY;

        oldOnMouseUpHandler = document.onmouseup;
        oldOnMouseMoveHandler = document.onmousemove;

        document.onmouseup = closeDragElementHandler;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDragHandler;
    }

    function elementDragHandler(e) {
        e = e || window.event;
        e.preventDefault();

        // calculate the new cursor position:
        offsetLeft = preClientX - e.clientX;
        offetTop = preClientY - e.clientY;
        preClientX = e.clientX > 0 ? e.clientX : 0;
        preClientY = e.clientY > 0 ? e.clientY : 0;
        let newTop = printDivElement.offsetTop > offetTop ? printDivElement.offsetTop - offetTop : 0;
        let newLeft = printDivElement.offsetLeft > offsetLeft ? printDivElement.offsetLeft - offsetLeft : 0;
        let maxTop = window.innerHeight - printDivElement.offsetHeight;
        let maxLeft = window.innerWidth - printDivElement.offsetWidth;
        if (newTop > maxTop) {
            preClientY = preClientY + maxTop - newTop;
            newTop = maxTop;
        }

        if (newLeft > maxLeft) {
            preClientX = preClientX + maxLeft - newLeft;
            newLeft = maxLeft;
        }

        // set the element's new position:
        printDivElement.style.top = newTop + "px";
        printDivElement.style.left = newLeft + "px";
    }

    function closeDragElementHandler() {
        // stop moving when mouse button is released
        document.onmouseup = null;
        document.onmousemove = null;

        // Re-assign old handlers
        if (oldOnMouseUpHandler) document.onmouseup = oldOnMouseUpHandler;
        if (oldOnMouseMoveHandler) document.onmousemove = oldOnMouseMoveHandler;

    }
}